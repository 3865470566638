import "./style.css";
import layout from "./layout.html";
import template from "./template.html";
import works from "@data/works";

export default class Works {
  static selector = ".works";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;
    this.workTemplate = document.createElement("div");
    this.workTemplate.innerHTML = template;
    this.workTemplate = this.workTemplate.querySelector("#work-template");
  }

  setupWork = (work) => {
    let workNode = this.workTemplate.content.firstElementChild.cloneNode(true);
    if (workNode) {
      let workImage = workNode.querySelector(".works__work__image");
      workImage.style.backgroundImage = `url(${work.cover})`;

      let workTitle = workNode.querySelector(".works__work__title");
      workTitle.textContent = work.title;

      return workNode;
    } else {
      return null;
    }
  };

  setupWorks = () => {
    return new Promise(async (resolve, reject) => {
      works.forEach((work, index) => {
        const workNode = this.setupWork(work);
        if (workNode) {
          this.block.appendChild(workNode);
        }
      });

      resolve();
    });
  };

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;
      await this.setupWorks();

      resolve();
    });
  };
}
