import "./style.css";
import { getScroll, getRaf } from "@app";
import { lerp, clamp, map } from "@utils/math";

export default class Colors {
  constructor() {
    this.colorTriggers = {};

    this.colors = {
      orange: {
        r: 254,
        g: 76,
        b: 16,
      },

      white: {
        r: 255,
        g: 255,
        b: 255,
      },
    };
    this.currentScroll = 0;
    this.animations = {
      backgroundColorChange: {
        node: this.itemsWrapper,
        to: 0,
        from: 0,
        current: 0,
        dampingFactor: 0.09,
        setValue: () => {
          this.animations.backgroundColorChange.from =
            this.animations.backgroundColorChange.from +
            (this.animations.backgroundColorChange.to -
              this.animations.backgroundColorChange.from) *
              this.animations.backgroundColorChange.dampingFactor;
          return this.animations.backgroundColorChange.from;
        },
        styles: {
          r: {
            toValue: 254,
            fromValue: 254,
            current: 254,
            setValue: () => {
              const value = lerp(
                this.animations.backgroundColorChange.styles.r.fromValue,
                this.animations.backgroundColorChange.styles.r.toValue,
                this.animations.backgroundColorChange.current
              );
              return value;
            },
          },

          g: {
            toValue: 76,
            fromValue: 76,
            current: 76,
            setValue: () => {
              const value = lerp(
                this.animations.backgroundColorChange.styles.g.fromValue,
                this.animations.backgroundColorChange.styles.g.toValue,
                this.animations.backgroundColorChange.current
              );
              return value;
            },
          },

          b: {
            toValue: 16,
            fromValue: 16,
            current: 16,
            setValue: () => {
              const value = lerp(
                this.animations.backgroundColorChange.styles.b.fromValue,
                this.animations.backgroundColorChange.styles.b.toValue,
                this.animations.backgroundColorChange.current
              );
              return value;
            },
          },
        },
      },

      colorChange: {
        node: this.itemsWrapper,
        to: 0,
        from: 0,
        current: 0,
        dampingFactor: 0.09,
        setValue: () => {
          this.animations.colorChange.from =
            this.animations.colorChange.from +
            (this.animations.colorChange.to -
              this.animations.colorChange.from) *
              this.animations.colorChange.dampingFactor;
          return this.animations.colorChange.from;
        },
        styles: {
          r: {
            toValue: 255,
            fromValue: 255,
            current: 255,
            setValue: () => {
              const value = lerp(
                this.animations.colorChange.styles.r.fromValue,
                this.animations.colorChange.styles.r.toValue,
                this.animations.colorChange.current
              );
              return value;
            },
          },

          g: {
            toValue: 255,
            fromValue: 255,
            current: 255,
            setValue: () => {
              const value = lerp(
                this.animations.colorChange.styles.g.fromValue,
                this.animations.colorChange.styles.g.toValue,
                this.animations.colorChange.current
              );
              return value;
            },
          },

          b: {
            toValue: 255,
            fromValue: 255,
            current: 255,
            setValue: () => {
              const value = lerp(
                this.animations.colorChange.styles.b.fromValue,
                this.animations.colorChange.styles.b.toValue,
                this.animations.colorChange.current
              );
              return value;
            },
          },
        },
      },
    };

    this.scroll = getScroll();
    document.querySelectorAll("[data-color]").forEach((node, index) => {
      this.scroll.registerOnScrollPositionChange(this.updateColors);
      node.dataset.scrollCall = `${node.dataset.color}_${index}`;
      this.scroll.registerOnInViewTrigger(
        node.dataset.scrollCall,
        this.colorEntered
      );
      this.colorTriggers[`${node.dataset.color}_${index}`] = node;
    });

    this.calculateWindowSizes();
    this.calculateTriggersTop();

    if (!this.raf) {
      this.raf = getRaf();
      this.raf.register("color", this.render);
    }
  }

  colorEntered = (scrollID, way, object) => {
    if (way === "enter") {
      this.colorTriggerKey = scrollID;

      const backgrooundColorKey = object.el.dataset.backgroundColor;
      const newBackgroundColor = this.colors[backgrooundColorKey];
      this.animations.backgroundColorChange.styles.r.toValue =
        newBackgroundColor.r;
      this.animations.backgroundColorChange.styles.g.toValue =
        newBackgroundColor.g;
      this.animations.backgroundColorChange.styles.b.toValue =
        newBackgroundColor.b;

      const colorKey = object.el.dataset.color;
      const newColor = this.colors[colorKey];
      this.animations.colorChange.styles.r.toValue = newColor.r;
      this.animations.colorChange.styles.g.toValue = newColor.g;
      this.animations.colorChange.styles.b.toValue = newColor.b;
    }
  };

  calculateTriggersTop = () => {
    for (const key in this.colorTriggers) {
      this.colorTriggers[key].calculatedTop =
        this.colorTriggers[key].getBoundingClientRect().top +
        this.currentScroll;
    }
  };

  calculateWindowSizes = () => {
    this.windowSizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  };

  updateColors = (scroll) => {
    if (this.colorTriggerKey) {
      this.currentScroll = scroll.y;

      let value =
        this.currentScroll +
        this.windowSizes.height -
        this.colorTriggers[this.colorTriggerKey].calculatedTop;
      value = clamp(map(value, 0, 200, 0, 1), 0, 1);
      this.animations.backgroundColorChange.to = value;
      this.animations.colorChange.to = value;
    }
  };

  layout = () => {
    const rColor = this.animations.colorChange.styles.r.current;
    const gColor = this.animations.colorChange.styles.g.current;
    const bColor = this.animations.colorChange.styles.b.current;
    document.documentElement.style.setProperty(
      "--text-color",
      `rgb(${rColor}, ${gColor}, ${bColor})`
    );

    const r = this.animations.backgroundColorChange.styles.r.current;
    const g = this.animations.backgroundColorChange.styles.g.current;
    const b = this.animations.backgroundColorChange.styles.b.current;
    document.documentElement.style.setProperty(
      "--background-color",
      `rgb(${r}, ${g}, ${b})`
    );
  };

  render = () => {
    for (const key1 in this.animations) {
      this.animations[key1].current = this.animations[key1].setValue();
      for (const key2 in this.animations[key1].styles) {
        this.animations[key1].styles[key2].current =
          this.animations[key1].styles[key2].setValue();
      }
    }

    this.layout();
  };
}
