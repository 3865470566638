import "./style.css";
import layout from "./layout.html";
import template from "./template.html";
import works from "@data/paperbarWorks";

export default class PaperbarWorks {
  static selector = ".paperbar";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;
    this.worksNode = block.querySelector(".paperbar__works");
    this.workTemplate = document.createElement("div");
    this.workTemplate.innerHTML = template;
    this.workTemplate = this.workTemplate.querySelector(
      "#paperbar-work-template"
    );
  }

  setupWork = (work) => {
    let workNode = this.workTemplate.content.firstElementChild.cloneNode(true);
    if (workNode) {
      let workImage = workNode.querySelector(".paperbar__works__work__image");
      workImage.style.backgroundImage = `url(${work.cover})`;

      return workNode;
    } else {
      return null;
    }
  };

  setupWorks = () => {
    return new Promise(async (resolve, reject) => {
      works.forEach((work, index) => {
        const workNode = this.setupWork(work);
        if (workNode) {
          this.worksNode.appendChild(workNode);
        }
      });

      resolve();
    });
  };

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;
      await this.setupWorks();

      resolve();
    });
  };
}
